import React, { useEffect } from "react";
import LandingPage from "./components/pages/LandingPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MyContext from "./Context/Context";
import ProjectDetails from "./components/pages/ProjectDetails";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      offset: 0,
    });
  }, []);
  return (
    <BrowserRouter>
      <MyContext>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/projects/:name" element={<ProjectDetails />} />
        </Routes>
      </MyContext>
    </BrowserRouter>
  );
}

export default App;
