import { FaUser, FaMoneyBill } from "react-icons/fa";
import { testimonials } from "./components/projectData";

export const ProjectData = [
  {
    name: "MyCreditFunding",
    description:
      "MyCreditFunding is your trusted partner for personalized loan solutions and expert credit guidance. We help you navigate your financial journey with tailored loans to fit your lifestyle and budget, whether consolidating debt or covering unexpected expenses. Our experienced team provides top-notch credit solutions, empowering you to build a strong financial future. Additionally, businesses can advertise on our platform to reach a financial-savvy audience. With transparency, expert advice, and customized services, we transform credit challenges into opportunities. Begin your path to financial freedom with MyCreditFunding your lane to success starts here!",
    images: ["./images/work-8-mycreaditfundeing.png"],
    fetuses: [
      // { icon: <FaUser />, text: "Personal Project" },
      { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    links: [
      {
        type: "website",
        link: "https://www.mycreditfunding.com/",
      },
    ],
    technology: ["next", "tailwind"],
    date: "Nov 15, 2024",
    clientReview: {
      name: testimonials[3]?.name,
      review: testimonials[3]?.text,
    },
  },
  {
    name: "ProForexTrading",
    description:
      "ProForexTrading is a full-stack website designed to empower individuals with the knowledge and skills to excel in forex trading and the share market. We offer expertly crafted trading courses and exclusive memberships to help you master the art of trading. With a seamless user experience, our platform includes features to manage courses, memberships, and secure payments through PayPal integration. Whether you're a beginner or an experienced trader, ProForexTrading provides the tools and insights needed to succeed in the trading world. Start your journey to financial independence with us today!",
    images: ["./images/work-7-proforextrading.png"],
    fetuses: [
      // { icon: <FaUser />, text: "Personal Project" },
      { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    links: [
      {
        type: "website",
        link: "https://proforextrading.vercel.app/",
      },
    ],
    technology: ["next", "mongoDB", "paypal", "tailwind"],
    date: "Aug 16, 2024",
    // clientReview: {
    //   name: "dhrumit panchal",
    //   review:
    //     "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, officia. Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis ducimus sapiente ullam perspiciatis ratione alias cupiditate. Hic laborum rem magni recusandae quos aperiam deleniti reiciendis eos aliquid in a velit dignissimos ex, quo temporibus officia nisi? Pariatur temporibus possimus fugit",
    // },
  },
  {
    name: "YesDoc Healthcare",
    description:
      "YesDoc Healthcare specializes in temporary staffing solutions for health and social care. We provide trusted services in domiciliary care, healthcare assistants, nursing, and allied health professionals (AHP) staffing. Our rigorous vetting process ensures highly skilled, compassionate professionals to meet short-term or long-term needs. By fostering trust, collaboration, and ongoing development, we empower our staff to deliver exceptional care. Whether you're a healthcare facility or a professional seeking opportunities, YesDoc Healthcare connects talent with excellence, elevating patient outcomes and redefining healthcare staffing.",
    images: ["./images/work-6-yesdocHealtcare.png"],
    fetuses: [
      // { icon: <FaUser />, text: "Personal Project" },
      { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    links: [
      {
        type: "website",
        link: "https://www.yesdochealthcare.co.uk/",
      },
    ],
    technology: ["next", "tailwind"],
    date: "May 13, 2024",
    clientReview: {
      name: "Jainam Jain",
      review:
        "Your outstanding work has significantly elevated our web presence. The website looks amazing and functions seamlessly, providing an excellent user experience. Your attention to detail, creativity, and problem-solving skills are invaluable. Keep up the fantastic work; you are a crucial and greatly appreciated part of our team!",
    },
  },

  {
    name: "urbanfashions",
    description:
      "UrbanFashions is a full-stack eCommerce platform built using the MERN stack, offering a seamless shopping experience. It includes essential features like product browsing, cart management, liked products, and secure checkout. The website also features an intuitive admin panel for efficiently tracking orders, managing products, and handling user details. UrbanFashions combines functionality with modern design, providing a user-friendly interface for customers and powerful tools for administrators. Perfect for fashion enthusiasts and business owners, this project showcases the power of technology in revolutionizing the online shopping experience.",
    images: ["./images/shopnest.png"],
    fetuses: [
      { icon: <FaUser />, text: "Personal Project" },
      // { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    links: [
      {
        type: "website",
        link: "https://urbanfashions.vercel.app",
      },
    ],
    technology: ["react", "node", "mongoDB", "express", "tailwind"],
    date: "Jul 10, 2024",
  },
  {
    name: "Taskify",
    description:
      "Taskify is a simple yet effective task management website designed with a clean and intuitive UI. Built using React and Firebase, it allows users to easily create, update, and manage their tasks. Whether you're organizing your daily to-dos or handling complex projects, Taskify provides a seamless experience for efficient task tracking. With real-time data synchronization, your tasks are always up to date across devices. This project highlights the power of React and Firebase in building user-friendly, dynamic applications that enhance productivity and task management.",
    fetuses: [
      { icon: <FaUser />, text: "Personal Project" },
      // { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    images: ["./images/work-5-Taskify.png"],
    links: [
      {
        type: "website",
        link: "https://task-manager-mu-olive.vercel.app/",
      },
    ],
    technology: ["react", "firebase", "tailwind"],
    date: "Jan 26, 2024",
  },

  {
    name: "TalkWave",
    description:
      "TalkWave is a personal project designed to enhance my skills in real-time communication using sockets. It is a chatting website featuring a clean and intuitive user interface for seamless interactions. Built with React Native, MongoDB, and Socket.io, TalkWave offers real-time messaging, ensuring instant and smooth communication between users. To handle media sharing efficiently, I integrated Cloudinary for image uploads, making it easy for users to share visuals during conversations. This project showcases my ability to create scalable, interactive, and user-friendly applications. TalkWave represents my passion for learning and implementing advanced web technologies, particularly sockets, while delivering a modern and engaging chat experience.",
    fetuses: [
      { icon: <FaUser />, text: "Personal Project" },
      // { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    images: ["./images/work-9-talkwave.png"],
    links: [
      {
        type: "website",
        link: "https://talk-wave-mu.vercel.app/",
      },
    ],
    technology: [
      "react",
      "socket",
      "tailwind",
      "mongoDB",
      "node",
      // "cloudinary",
    ],
    date: "May 17, 2024",
  },

  {
    name: "Rakesh meena's portfolio",
    description:
      "This is Portfolio is a modern and visually stunning website designed to showcase your client's skills, projects, and services. Built with Next.js and styled using Tailwind CSS, the website features a sleek, responsive UI and smooth animations, creating an engaging user experience. This portfolio highlights the client's expertise and achievements in an attractive, easy-to-navigate format, with optimized performance and fast load times. Perfect for professionals looking to make a strong impression online, this project demonstrates the power of Next.js and Tailwind CSS in building dynamic, visually appealing websites.",
    images: ["./images/work-4-clientPortfolio.png"],
    fetuses: [
      // { icon: <FaUser />, text: "Personal Project" },
      { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    links: [
      {
        type: "website",
        link: "https://client-portfolio-by-dhrumitpanchal.netlify.app/",
      },
    ],
    technology: ["next", "tailwind"],
    date: "Nov 2, 2023",
    clientReview: {
      name: "Rakesh Meena",
      review:
        "Dhrumit's exceptional frontend skills, design acumen, and tech proficiency significantly enhance any project. His commitment to staying current with trends ensures visually stunning, user-friendly interfaces that align with modem design principles. He's a standout developer for Ul work.",
    },
  },
  {
    name: "Caffeine",
    description:
      "Caffeine is a stylish and creative coffee shop website designed to showcase a variety of coffee offerings. Built with React.js and styled using Tailwind CSS, the website features an engaging, modern UI that provides a seamless browsing experience. Visitors can explore different types of coffee, from espresso to specialty brews, with detailed descriptions and vibrant visuals. Perfect for coffee enthusiasts, Caffeine offers an inviting platform to learn about and discover new coffee experiences, all while highlighting the beauty and craftsmanship of the coffee world through its intuitive design and smooth navigation.",
    images: ["./images/caffeine.png", "./images/work-1-caffine.png"],
    fetuses: [
      { icon: <FaUser />, text: "Personal Project" },
      // { icon: <FaMoneyBill />, text: "Freelancing Project" },
    ],
    links: [
      {
        type: "website",
        link: "https://caffeine-coffee.netlify.app/",
      },
    ],
    technology: ["react", "tailwind"],
    date: "Aug 5, 2023",
  },
];
