import React, { Component } from "react";
import Slider from "react-slick";
import { FaQuoteLeft, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonials } from "./projectData";

function SamplePrevArrow(props) {
  return <div className="hidden" />;
}

export default class PreviousNextMethods extends Component {
  next = this.next.bind(this);
  previous = this.previous.bind(this);
  state = {
    carcoun: window.innerWidth >= 640 ? 3 : 1,
    cardots: window.innerWidth < 330 ? false : true,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 2000,
      autoplaySpeed: 6000,
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    const { ThemTextColor, ThemBGColor } = this.props.themeColor;
    const {
      ModeBgColor_100,
      ModeBorderColor_50,
      ModeTextColor_900,
      ModeTextColor_700,
    } = this.props.mode;
    return (
      <>
        <div className="">
          <div
            data-aos="zoom-in"
            data-aos-duration="500"
            className="max-sm:mt-[3rem] mx-auto relative flex items-center justify-center "
          >
            <Slider
              ref={(c) => (this.slider = c)}
              {...settings}
              className="mx-auto  max-sm:w-[26.5rem] w-[38rem]"
            >
              {testimonials?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="max-sm:mx-0 w-[38rem]  max-sm:h-[32rem] flex justify-center gap-[1.6rem]"
                  >
                    <div
                      className={`mx-auto flex flex-col justify-between gap-[1rem] h-[26rem] max-sm:h-fit w-[32rem] max-sm:w-[26rem] p-[2rem] ${ModeBgColor_100} rounded-[.8rem] border-[1px] ${ModeBorderColor_50} `}
                    >
                      <h2 className={`text-[2rem] ${ThemTextColor} `}>
                        <FaQuoteLeft />
                      </h2>
                      <div>
                        <p className="text-[1.1rem]">{item?.text}</p>
                        <div className="mt-[.2rem] text-[1rem] font-semibold">
                          {item?.date}
                        </div>
                      </div>

                      <div className="flex items-center justify-end text-[1rem] text-right">
                        <span className="mr-2 text-2xl">-</span>
                        <h2 className="font-medium text-lg capitalize"> {item?.name}</h2>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* ------------------------------------------------- */}
            </Slider>
            <div className="absolute flex justify-between items-center w-[60rem] -translate-y-[1rem] text-[1.5rem] text-[#fdf9ff]">
              <div
                onClick={this.previous}
                className={`flex justify-center items-center h-[2.8rem] w-[4.5rem] ${ThemBGColor} rounded-[1.5rem] `}
              >
                <FaArrowLeft />
              </div>
              <div
                onClick={this.next}
                className={`flex justify-center items-center h-[2.8rem] w-[4.5rem] ${ThemBGColor} rounded-[1.5rem] `}
              >
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
