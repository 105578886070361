import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/Context";
import { ProjectData } from "../../projectData";
import {
  FaUser,
  FaMoneyBill,
  FaReact,
  FaGithub,
  FaLink,
  FaTimes,
} from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { SiTailwindcss } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";
import { TbBrandNextjs } from "react-icons/tb";
import { DiMongodb } from "react-icons/di";
import { SiExpress } from "react-icons/si";
import { FaPaypal } from "react-icons/fa6";
import { IoLogoFirebase } from "react-icons/io5";
import { SiSocketdotio } from "react-icons/si";
// import { SiCloudinary } from "react-icons/si";

import { useLocation, useParams } from "react-router-dom";
function ProjectDetails() {
  const { name } = useParams();
  const { mode, theme } = useContext(Context);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const project = ProjectData?.filter((item) => {
      return item.name.toLowerCase().includes(name.toLowerCase());
    });
    setProjectData({ ...project[0] });
    setLoading(false);
  }, []);

  return (
    <>
      {fullScreenImage && (
        <div className="max-sm:hidden fixed flex gap-[1rem] py-[2rem] justify-center items-center z-50 top-0 left-0 backdrop-blur-[2px] h-screen w-full ">
          <img
            className=" h-full max-sm:w-full max-sm:h-fit rounded-[1rem]"
            src={projectData?.images[activeImage]}
            alt=""
          />
          <div
            onClick={() => setFullScreenImage(false)}
            className={`cursor-pointer flex self-start justify-center items-center h-[3rem] w-[3rem] rounded-[.6rem] border-[1px] ${mode.ModeBorderColor_50} ${mode.ModeBgColor_100}`}
          >
            <FaTimes className={`text-2xl ${mode.ModeTextColor_900}`} />
          </div>
        </div>
      )}

      {!loading ? (
        <div
          className={`relative py-[4rem] max-sm:py-7 max-sm:px-6 flex max-sm:flex-col justify-between h-screen max-sm:h-fit w-full ${mode?.ModeBgColor_900}`}
        >
          <div
            onClick={() => window.history.back()}
            className={`cursor-pointer absolute flex justify-center items-center top-4 left-3 h-9 w-9 rounded-md border-[1px] ${mode.ModeBorderColor_50} ${mode.ModeBgColor_100} ${mode.ModeTextColor_900}`}
          >
            <FaArrowLeft className="text-xl" />
          </div>
          <h2
            className={`hidden max-sm:mt-10 max-sm:block mb-4 text-[2rem] tracking-[.5px] font-bold ${theme.ThemTextColor}`}
          >
            {projectData?.name}
          </h2>
          <div className="flex flex-col gap-[1rem] w-1/4 max-sm:w-full">
            <img
              onClick={() => setFullScreenImage(true)}
              className={`cursor-pointer  w-full rounded-[.6rem] border-[1px] ${mode.ModeBorderColor_50}`}
              src={projectData?.images[activeImage]}
              alt=""
            />

            <div className="pb-[.6rem] pt-2 pl-4 flex gap-[1rem] w-full overflow-x-auto ">
              {projectData?.images.map((item, index) => {
                return (
                  <img
                    key={index}
                    onClick={() => setActiveImage(index)}
                    className={`w-[7rem] rounded-[.6rem]  ${
                      activeImage === index &&
                      `border-[3px] scale-[1.16] mr-1 ${theme.ThemBorderColor}`
                    }`}
                    src={item}
                    alt=""
                  />
                );
              })}
            </div>

            <div
              className={`mt-[.6rem] py-[.6rem] px-[2rem] flex flex-col gap-[1rem] w-full rounded-[.6rem] ${mode.ModeBgColor_100}  ${mode.ModeTextColor_900}`}
            >
              {projectData?.links?.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`flex items-center gap-[1rem] pb-[.6rem] hover:${theme.ThemTextColor} duration-300 transition-colors`}
                  >
                    {item.type === "website" && (
                      <FaLink className={` mt-[.1rem] text-[1.4rem]`} />
                    )}
                    {item.type === "github" && (
                      <FaGithub className={` mt-[.1rem] text-[1.4rem]`} />
                    )}
                    <h2
                      className={` underline w-[19rem] text-[.9rem]  truncate ... `}
                    >
                      {item?.link}
                    </h2>
                  </a>
                );
              })}
            </div>
          </div>

          <div
            className={`flex flex-col gap-[.8rem]  w-3/5 max-sm:w-full ${mode.ModeTextColor_900}`}
          >
            <h2
              className={`block max-sm:hidden  text-[2rem] tracking-[.5px] font-bold ${theme.ThemTextColor}`}
            >
              {projectData?.name}
            </h2>
            <h2 className="w-[90%] max-sm:w-full max-sm:mt-8">
              {projectData?.description}
            </h2>

            <div
              className={`mt-[.4rem] flex flex-wrap gap-[1rem] ${mode.ModeTextColor_900}`}
            >
              <div
                className={`flex h-[2.4rem] gap-[.6rem] items-center justify-center border-[1px] ${mode.ModeBgColor_100} ${mode.ModeBorderColor_50} px-[1.1rem] rounded-[.2rem] py-[.2rem] w-fit`}
              >
                {projectData?.date}
              </div>

              {projectData?.fetuses?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`flex  h-[2.4rem] gap-[.6rem] items-center justify-center border-[1px]  ${mode.ModeBgColor_100} ${mode.ModeBorderColor_50} px-[1.1rem] rounded-[.2rem] py-[.2rem] w-fit`}
                  >
                    {item?.icon}
                    <h2>{item?.text}</h2>
                  </div>
                );
              })}
            </div>

            <div className="mt-[.8rem]  flex flex-wrap gap-[1.2rem]">
              {projectData?.technology.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`group relative flex justify-center items-center h-[5rem] w-[5rem] rounded-[.6rem] border-[1px] ${mode.ModeBorderColor_50} ${mode.ModeBgColor_100}`}
                  >
                    {/* <div
                      className={`group-hover:block hidden absolute -top-4 text-sm font-normal px-3 pb-[1px] rounded-sm border-[.2px] capitalize ${mode.ModeBorderColor_50} ${mode.ModeBgColor_900}`}
                    >
                      {item}
                    </div> */}
                    {item === "react" && (
                      <FaReact
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "tailwind" && (
                      <SiTailwindcss
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "node" && (
                      <FaNodeJs
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "next" && (
                      <TbBrandNextjs
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "mongoDB" && (
                      <DiMongodb
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "express" && (
                      <SiExpress
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "paypal" && (
                      <FaPaypal
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}

                    {item === "firebase" && (
                      <IoLogoFirebase
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}
                    {item === "socket" && (
                      <SiSocketdotio
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )}
                    {/* {item === "cloudinary" && (
                      <SiCloudinary
                        className={`${theme.ThemTextColor} text-[2.6rem]`}
                      />
                    )} */}
                  </div>
                );
              })}
            </div>

            {projectData?.clientReview && (
              <div className={`mt-[.2rem] ${mode.ModeTextColor_900}`}>
                <h2
                  className={`text-[1.4rem] tracking-[.5px] font-bold ${theme.ThemTextColor}`}
                >
                  Client Review
                </h2>
                <div className={`flex flex-col w-full rounded-[.6rem] `}>
                  <div className="mt-2">
                    <p className={` text-[1.1rem] `}>
                      {projectData?.clientReview?.review}
                    </p>
                    <h2 className="mt-3 text-end capitalize text-[1.15rem] mr-4 font-medium">
                      {"--  " + projectData?.clientReview?.name}
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`relative py-[4rem] max-sm:py-7 max-sm:px-6 flex max-sm:flex-col justify-between items-center h-screen max-sm:h-fit w-full ${mode?.ModeBgColor_900}`}
        >
          <svg
            aria-hidden="true"
            class={`w-12 h-12 text-gray-200 animate-spin  fill-blue-600`}
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill={"#3b3b3b"}
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill={"#ec1839"}
            />
          </svg>
        </div>
      )}
    </>
  );
}

export default ProjectDetails;
